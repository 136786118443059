<template>
  <div class="verification" id="verification">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Verification</p>
        <h3>Verification</h3>

        <h4 id="v1">Why does my account need to be Verified?</h4>

        <div class="paragraph">
          <p>
            As a Basic Member, you can already use our Cash In, Pay Bills, and Buy Load services. In order to unlock all of TayoCash’s features and/or enroll to become a Partner or Merchant, you need to undergo full verification.
          </p>
          <p>
            You may click <a class="link" href="/faq/membership">here</a> to learn more about the different TayoCash verification levels.
          </p>
        </div>

        <h4 id="v2">Steps in Verification</h4>
        <div class="paragraph">
          <p>
            To upgrade from Basic to a Semi-Verified or Fully Verified Member Account, just follow these simple instructions:
          </p>

          <p>
            1. Fill out all Required <span class="vtxt-align">Personal Information</span>.
          </p>
          <p><i>
            Please see our <a class="link" href="/privacypolicy">Privacy Policy</a>  to learn how TayoCash ensures the security and confidentiality of your personal information.
          </i></p>
          <p>
            2. Press the <span class="vtxt-align">Take a Video Selfie</span> button. Make sure you are in a well-lighted area.
          </p>
          <p>
            3. Position your face inside the circle and follow the instructions indicated on the screen. Your app will notify you if your selfie attempt has been successful.
          </p>
          <p>
            4. Upload a <span class="vtxt-align">Valid ID</span> and submit for <span class="vtxt-align">Full Verification</span> of your account. If you do not have a Valid ID with you, you can submit later on and we will review your information and video selfie for a <span class="vtxt-align">Semi-Verified Member Account</span> in the meantime.
          </p>
          <p>
            5. An SMS and in-app notification will be sent to you once review is complete and your Semi-Verified or Fully Verified Member Account is approved.
          </p>
        </div>

        <h4 id="v3">Steps in Uploading ID</h4>
        <!-- add philsys id nov 7 -->
        <div class="paragraph">
          <p class="no-margin">1. Choose the type of ID to upload from the list in the app:</p>
          <div class="ui grid two column row stackable">
            <div class="column">
              <p class="vtxt">Recommended ID:</p>
              <ul>
                <li>Philsys ID/ePhilsys ID</li>
                <li>Passport</li>
                <li>Driver’s License</li>
                <li>UMID </li>
                <li>SSS </li>
                <li>Postal ID (digitized with photo)</li>
                <li>Student ID (for minor)</li>
              </ul>
            </div>
            <div class="column">
              <p class="vtxt">Other ID:</p>
              <ul>
                <li>Philhealth ID (digitized with photo)</li>
                <li>PRC ID</li>
                <li>OFW ID</li>
                <li>Voter’s ID</li>
                <li>IBP ID</li>
                <li>Alien Certificate of Registration (ACR)</li>
                <li>PSA Birth Certificate (for minor)</li>
              </ul>
            </div>
          </div>

          <p>
            2. Take a photo of your ID or, if you have an image of your ID saved in your photo gallery, upload it. It will show if the upload of your ID is successful.
          </p>
          <p><i>
            For other inquiries, please <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">submit a ticket</router-link> via the <a href="/helpcenter" class="link">Help Center</a> in the TayoCash App or email TayoCash Customer Care Group at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>.
          </i></p>
        </div>

        <div class="buttons">
          <a href="/faq/membership" class="yellow">BACK</a>
          <a href="/faq/partnermerchant" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'content1'
}
</script>

<style scoped lang='scss'>
</style>
